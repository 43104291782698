<template>
  <div class="MadeBy clear">
    <div class="MadeBy--Half">
      <p>{{ $t('widget.poweredBy') }}</p>
    </div>
    <div class="MadeBy--Half">
      <a
        href="https://www.helloasso.com/associations"
        target="_blank"
        rel="noopener noreferrer"
        :data-ux="`Forms_${metadata.type}Widget_MadeBy_BackToHelloAsso`"
      >
        <img
          src="~assets/images/logos/logo-helloasso-midnight.svg"
          alt="HelloAsso"
          loading="lazy"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProvidedByHelloAsso'
}
</script>

<style lang="scss">
.MadeBy {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $ha-unit * 48; // 384px
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  background-clip: padding-box;
  border: $ha-border-regular;
  border-radius: $ha-radius-round;

  &--Half {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $ha-unit * 6.75; // 54px
    padding: $ha-spacing-medium;
    text-transform: uppercase;
    background-color: var(--ha-color-white);

    &:first-of-type {
      flex: 1 1 auto;
      background-color: var(--ha-color-text);
    }

    &:last-of-type {
      flex: 0 0 $ha-unit * 20; // 160px
    }
  }

  p {
    margin: 0;
    color: var(--ha-color-white);
    font-weight: 700;
    font-size: $ha-font-size-small;

    @include mediaQuery(600) {
      font-size: $ha-font-size-regular;
    }
  }

  a {
    width: 100%;
    max-height: $ha-unit * 3.375; // 27px
  }
}
</style>
